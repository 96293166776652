import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, NavLink, Routes, useLocation, useParams } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Contact from './components/Contact';


import BlogPost from './blogMeta/blogPost'; 
import { blogPosts } from './blogMeta/blogData'; 
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCQtNQrMkYPQeH39kkniCXnpXEQSw4Vs5Y",
  authDomain: "cn-invest.firebaseapp.com",
  projectId: "cn-invest",
  storageBucket: "cn-invest.appspot.com",
  messagingSenderId: "377722271593",
  appId: "1:377722271593:web:c874a7457a2a2bb0516ff8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function TrackPageView() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  }, [location]);

  return null;
}

function BlogPostWrapper() {
  const { blogId } = useParams(); 
  const blog = blogPosts.find(blog => blog.id === blogId); 

  if (!blog) {
    return <div>blog not found :|</div>;
  }
  return <BlogPost blog={blog} />; 
}

function App() {
  return (
    <Router>
      <TrackPageView />
      <div className="App">
        <nav className="menu-bar">
          <NavLink exact="true" to="/" className="menu-item" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/contact" className="menu-item" activeClassName="active">
            About
          </NavLink>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />


          <Route path='/blog/:blogId' element={<BlogPostWrapper />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
