import React from 'react';
import { useNavigate } from 'react-router-dom';
import PanelX from './PanelX';
import { blogPosts } from '../blogMeta/blogData'; 
import '../App.css'; 

function getRelativeDate(dateString) {
  const postDate = new Date(dateString);
  const currentDate = new Date();
  const timeDifference = currentDate - postDate;

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    return 'Today';
  } else if (daysDifference === 1) {
    return '1 day ago';
  } else if (daysDifference < 7) {
    return `${daysDifference} days ago`;
  } else if (daysDifference < 30) {
    const weeksDifference = Math.floor(daysDifference / 7);
    return `${weeksDifference} week${weeksDifference > 1 ? 's' : ''} ago`;
  } else {
    return dateString; // If more than a month ago, just return the date
  }
}

function Home() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    setTimeout(() => {
      navigate(path);
    }, 600);
  };

  const sortedBlogs = [...blogPosts].sort((a, b) => new Date(b.date) - new Date(a.date)); 


return (
    <div className="home">
      <h2>Intellecture</h2>
      <h3>Key Insights of Major Tech Interviews. </h3>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {sortedBlogs.map((blog) => (
          <li key={blog.id} className="PanelX-container">
            <div
              style={{ textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => handleNavigation(`/blog/${blog.id}`)} 
            >
              <PanelX title={blog.title} desc={getRelativeDate(blog.date)} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Home;
