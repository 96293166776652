import React from 'react';

function Contact() {
  return (
    <div>
      <h1>About</h1>
      <p>Intellecture aims to be a comprehensive collection of the intellectual outputs of tech gurus.  </p>
      <p>WeChat：老和山下的小学僧</p>
    </div>
  );
}

export default Contact;
