import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material'; 

export default function PanelX({ title, desc }) {
    return (
        <Card sx={{ width: '51vw' }}>
            <CardActionArea>
                <CardContent sx={{ textAlign: 'left' }}>
                    <Typography gutterBottom variant='h6' component='div'>
                        {title}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        {desc}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    ); 
}