import React from 'react';
import '../App.css';

const BlogPost = ({ blog }) => {
    return (
        <div className="blog-container">
            <h1>{blog.title}</h1>
            <p className="blog-metadata">{blog.date}</p>
            <div>{blog.content}</div> {/* Render the content directly */}
        </div>
    );
};

export default BlogPost;
